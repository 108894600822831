import React from "react";
import { CartProvider } from "./cart";

const GlobalProvider = ({ children }) => (
    <CartProvider>
        { children }
    </CartProvider>
);

export default GlobalProvider;
