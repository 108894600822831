import axios from "axios";
import queries from "./wp-graphql";

const baseURL = `${process.env.GATSBY_WORDPRESS_URL}/wp-json/yp/v1`;
const graphqlURL = process.env.GATSBY_WPGRAPHQL_URL;

export const validateCode = async (code) => {
    try {
        const codeValidate = await axios.post(`${baseURL}/validate-activation-code`, JSON.stringify({ code }), { headers: { "Content-Type": "application/json" } });
        return { code, product: codeValidate.data };
    } catch (error) {
        return { error: "Ce code est invalide" };
    }
};

export const fetchChildren = async (token) => {
    try {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(`${baseURL}/my-children`, config);
        return response.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const activateCode = async (token, data) => {
    try {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const activate = await axios.post(`${baseURL}/activate-code`, data, config);
        return activate.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const fetchProfile = async (token) => {
    try {
        const config = { headers: { Authorization: `Bearer ${await token}` } };
        const profile = await axios.get(`${baseURL}/profile`, config);
        return profile.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const changeProfile = async (token, data) => {
    try {
        const config = { headers: { Authorization: `Bearer ${await token}` } };
        const profile = await axios.post(`${baseURL}/change-profile`, data, config);
        return profile.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const createCheckoutSession = async (token, cart, address) => {
    try {
        const config = { headers: { Authorization: `Bearer ${await token}` } };
        const checkout = await axios.post(`${baseURL}/checkout`, { cart, address }, config);
        return checkout.data;
    } catch (error) {
        return { error };
    }
};

export const submitContactForm = async (formId, data) => {
    try {
        const response = await axios.post(`${process.env.GATSBY_WORDPRESS_URL}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`, data, { headers: { "Content-Type": "multipart/form-data" } });
        return response.data;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const fetchSessionToken = async () => {
    try {
        const response = await axios.post(graphqlURL, { query: queries.userSessionQuery });
        return response.data.data.customer.sessionToken;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const getSessionToken = async () => {
    const token = localStorage.getItem("sessionToken");
    if (!token) {
        const newToken = await fetchSessionToken();
        localStorage.setItem("sessionToken", newToken);
        return newToken;
    }
    return token;
};

const gqlCartRequest = async (query, variables) => {
    const token = await getSessionToken();
    const response = await axios.post(
        graphqlURL, 
        { query, ...(variables && { variables }) }, 
        { headers: { "woocommerce-session": `Session ${token}` } },
    );
    if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
    }
    return response;
};

export const getCart = async () => {
    try {
        const response = await gqlCartRequest(queries.cart);
        return response.data.data;
    } catch (error) {
        localStorage.removeItem("sessionToken");
        await getCart();
        return { error: error.response.data.message };
    }
};

export const emptyCart = async () => {
    try {
        const response = await gqlCartRequest(queries.clearCart);
        return response.data.data.emptyCart;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const addItem = async (pId, vId, extraData) => {
    try {
        const response = await gqlCartRequest(queries.addItem, {
            pId, vId, extraData,
        });
        return response.data.data.addToCart;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const removeItem = async (key) => {
    try {
        const response = await gqlCartRequest(queries.removeItem, { keys: [key] });
        return response.data.data.removeItemsFromCart;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const updateItemQuantities = async (key, quantity) => {
    try {
        const response = await gqlCartRequest(queries.updateItemQuantities, { items: [{ key, quantity }] });
        return response.data.data.updateItemQuantities;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const applyDiscountCode = async (code) => {
    try {
        const response = await gqlCartRequest(queries.applyCoupon, { code });
        if (response.data.error) {
            throw new Error(response.data.error.message);
        }
        return response.data.data.applyCoupon;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const removeDiscountCodes = async (codes) => {
    try {
        const onlyCodes = codes.map((code) => code.code);
        const response = await gqlCartRequest(queries.removeCoupons, { codes: onlyCodes[0] });
        return response.data.data.removeCoupons;
    } catch (error) {
        return { error: error.response.data.message };
    }
};

export const getVariantQuantity = async (variantId) => {
    try {
        const response = await gqlCartRequest(queries.variantQuantity, { id: variantId });
        return response.data.data.productVariation;
    } catch (error) {
        return { error: error.response.data.message };
    }
};
