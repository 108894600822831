import React, {
    createContext, useCallback, useEffect, useMemo, useState,
} from "react";
import {
    getCart, addItem, removeItem, applyDiscountCode, removeDiscountCodes, emptyCart, updateItemQuantities,
} from "services/wp-services";

export const CartContext = createContext();

const initialCart = {
    appliedCoupons: null,
    contents: { edges: [] },
    isEmpty: true,
    initial: true,
};

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(initialCart);
    const [loading, setLoading] = useState(true);

    const errorHandler = (error) => {
        // eslint-disable-next-line no-console
        console.error(error); 
        setLoading(false);
        return error;
    };

    const fetchCart = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getCart();
            setCart(response.cart);
        } catch (error) {
            errorHandler(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchCart();
    }, [fetchCart]);

    const clearCart = useCallback(async () => {
        setLoading(true);
        try {
            const response = await emptyCart();
            setCart(response.cart);
        } catch (error) {
            errorHandler(error);
        } finally {
            setLoading(false);
        }
    }, []);

    const addCartItem = useCallback(async (pId, vId, extraData = {}) => {
        setLoading(true);
        try {
            const response = await addItem(pId, vId, JSON.stringify(extraData));
            setCart(response.cart);
        } catch (error) {
            errorHandler(error);
        } finally {
            setLoading(false);
        }
    }, []);

    const removeCartItem = useCallback(async (key) => {
        setLoading(true);
        try {
            const response = await removeItem(key);
            setCart(response.cart);
        } catch (error) {
            errorHandler(error);
        } finally {
            setLoading(false);
        }
    }, []);

    const changeItemQuantity = useCallback(async (key, quantity) => {
        setLoading(true);
        try {
            const response = await updateItemQuantities(key, quantity);
            setCart(response.cart);
        } catch (error) {
            errorHandler(error);
        } finally {
            setLoading(false);
        }
    }, []);

    const removeCoupon = useCallback(async (inner = false) => {
        setLoading(true);
        try {
            if (cart?.appliedCoupons) {
                const response = await removeDiscountCodes(cart.appliedCoupons);
                setCart(response.cart);
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            if (!inner) {
                setLoading(false);
            }
        }
    }, [cart?.appliedCoupons]);

    const applyCoupon = useCallback(async (code, setError) => {
        setLoading(true);
        try {
            await removeCoupon(true);
            const response = await applyDiscountCode(code);
            setCart(response.cart);
            setError(false);
        } catch (error) {
            errorHandler(error);
        } finally {
            setLoading(false);
        }
    }, [removeCoupon]);

    const contextValue = useMemo(() => ({
        cart,
        loading,
        setLoading,
        fetchCart,
        clearCart,
        addCartItem,
        removeCartItem,
        changeItemQuantity,
        removeCoupon,
        applyCoupon,
    }), [cart, loading, fetchCart, clearCart, addCartItem, removeCartItem, changeItemQuantity, removeCoupon, applyCoupon]);

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};
