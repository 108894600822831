import React from "react";
import "./src/styles/global.css";
import GlobalProvider from "./src/context";

export const wrapRootElement = ({ element }) => (
    <GlobalProvider>
        {element}
    </GlobalProvider>
);

export const onClientEntry = () => {
    // Scroll to top on client-side navigation without a hash
    window.scrollTo(0, 0)
}

export const onRouteUpdate = ({ location }) => {
    if (!location.hash) {
        window.scrollTo(0, 0)
    }
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    window.history.scrollRestoration = 'manual'
    const currentPosition = getSavedScrollPosition(location) || [0, 0]

    setTimeout(() => {
        if (location.hash) {
            // If hash (`/#something`), smooth scroll to that position
            const item = document.querySelector(`${location.hash}`)
            item?.scrollIntoView({ behavior: 'smooth' })
        } else {
            // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
            window.scrollTo({
                top: currentPosition[1],
                left: currentPosition[0],
                behavior: 'instant',
            })
        }
    }, 0)

    // do not let gatsby do anything more
    return false
}