const CartFragment = `
appliedCoupons {
  code
  discountAmount(format: RAW)
  description
}
total(format: RAW)
subtotal(format: RAW)
isEmpty
discountTotal(format: RAW)
contentsTotal
contents {
  edges {
    node {
      extraData {
        key
        value
      }
      key
      quantity
      total(format: RAW)
      subtotal(format: RAW)
      product {
        node {
          name
          title
          product_id: databaseId
          ... on VariableProduct {
            id
            name
            ypProductAttributes {
              productName
            }
          }
        }
      }
      variation {
        node {
          price(format: RAW)
          name
          salePrice(format: RAW)
          regularPrice(format: RAW)
          variant_id: databaseId
          image {
            sourceUrl
          }
        }
        attributes {
          name
          value
        }
      }
    }
  }
}`;

const userSessionQuery = `
query userSessionToken {
    customer {
      sessionToken
    }
  }`;

const cart = `query Cart {
  cart {
    ${CartFragment}
  }
}`;

const clearCart = `mutation {
  emptyCart(input: {clearPersistentCart: true}) {
    cart {
      ${CartFragment}
    }
  }
}`;

const addItem = `
mutation($pId: Int!, $vId: Int!, $extraData: String!) {
  addToCart(
    input: {productId: $pId, variationId: $vId, extraData: $extraData, quantity: 1}
  ) {
    cart {
      ${CartFragment}
    }
  }
}`;

const removeItem = `
mutation ($keys: [ID!]!) {
  removeItemsFromCart(input: {keys: $keys}) {
    cart {
      ${CartFragment}
    }
  }
}`;

const updateItemQuantities = `
mutation ($items: [CartItemQuantityInput!]!) {
  updateItemQuantities(input: {items: $items}) {
    cart {
      ${CartFragment}
    }
  }
}`;

const applyCoupon = `
mutation ($code: String!) {
  applyCoupon(input: {code: $code}) {
    cart {
      ${CartFragment}
    }
  }
}`;

const removeCoupons = `
mutation ($codes: [String!]!) {
  removeCoupons(input: {codes: $codes}) {
    cart {
      ${CartFragment}
    }
  }
}`;

const variantQuantity = `
query ($id: ID) {
  productVariation(id: $id, idType: DATABASE_ID) {
    stockQuantity
    stockStatus
  }
}
`;

export default {
    userSessionQuery,
    cart,
    clearCart,
    addItem, 
    removeItem,
    updateItemQuantities,
    applyCoupon,
    removeCoupons,
    variantQuantity,
};
